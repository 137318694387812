<template>
  <div class="header other-pages">
    <v-row justify="center">  <h1 class="close-hd">
      Not Accepting Order Today
    </h1>

    </v-row>
    <div class="text-center">
      <v-img
        src="@/assets/images/images.png"
        alt="Image"
        class="rounded-0 item-img cursor-pointer mx-auto mt-10"
      />
    </div>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        pk="pk_live_51IG5WTG4BLFb3DxkREyj6xICii04SiFpgCKI4yYMKUaY4QnKtXMvCppcI6KFFXUVewDPTiyJeAZzp7WBFAQp22M300kShuVnQE"
        :success-url="successUrl"
        :cancel-url="cancelUrl"
      >
        <template slot="checkout-button">
          <button @click="checkout">
            Shutup and take my money!
          </button>
        </template>
      </stripe-checkout>
    </div>

  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'

export default {
  components: {
    StripeCheckout,
  },
  // setup() {
  //   debugger

  //   const loading = () => {
  //     console.log('hhhh')
  //     debugger
  //   }
  //   return {
  //     loading,
  //   }
  // },
  data: () => ({
    loading: false,
    items: [
      {
        sku: 'sku_FdQKocNoVzznpJ',
        quantity: 1,
      },
    ],
    successUrl: 'your-success-url',
    cancelUrl: 'your-cancel-url',
  }),
  methods: {
    checkout() {
      this.$refs.checkoutRef.redirectToCheckout()
    },
  },
}
</script>
