var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header other-pages" },
    [
      _c("v-row", { attrs: { justify: "center" } }, [
        _c("h1", { staticClass: "close-hd" }, [
          _vm._v(" Not Accepting Order Today ")
        ])
      ]),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("v-img", {
            staticClass: "rounded-0 item-img cursor-pointer mx-auto mt-10",
            attrs: { src: require("@/assets/images/images.png"), alt: "Image" }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "stripe-checkout",
            {
              ref: "checkoutRef",
              attrs: {
                pk:
                  "pk_live_51IG5WTG4BLFb3DxkREyj6xICii04SiFpgCKI4yYMKUaY4QnKtXMvCppcI6KFFXUVewDPTiyJeAZzp7WBFAQp22M300kShuVnQE",
                "success-url": _vm.successUrl,
                "cancel-url": _vm.cancelUrl
              }
            },
            [
              _c("template", { slot: "checkout-button" }, [
                _c("button", { on: { click: _vm.checkout } }, [
                  _vm._v(" Shutup and take my money! ")
                ])
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }